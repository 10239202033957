<ng-template #header let-opened="opened" let-images="images" let-texts="texts" let-count="count">
  <span class="d-flex align-items-center">
    <img class="header-icon align-items-center filter-basic-600" src="{{images['header']}}" alt="header-icon">
    <span class="header-text align-items-center color-basic-600">
      <span>{{count}} </span>
      <span *ngIf="count > 1">{{texts['many'] | translate}}</span>
      <span *ngIf="count <= 1">{{texts['one'] | translate}}</span>
    </span>
  </span>
  <img *ngIf="!opened" src="../../../../assets/images/icon-chevron-down.svg" alt="chevron-down"/>
  <img *ngIf="opened" src="../../../../assets/images/icon-chevron-up.svg" alt="chevron-up"/>
</ng-template>

<ng-template #nocontent let-images="images" let-texts="texts">
  <div class="empty-content">
    <img class="filter-basic-500" src="{{images['empty']}}" alt="empty-icon">
    <span class="color-basic-600">{{texts['empty'] | translate}}</span>
  </div>
</ng-template>

<ngb-accordion *ngIf="article" [closeOthers]="true"
               [activeIds]="(article.impacters.length && 'impacters-panel') || ''">
  <ngb-panel id="impacters-panel">
    <ng-template ngbPanelHeader let-opened="opened">
      <button ngbPanelToggle
              class="btn btn-link container-fluid text-left d-flex align-items-center justify-content-between">
        <ng-container [ngTemplateOutlet]="header"
                      [ngTemplateOutletContext]="{opened, images: imCtx.images, texts: imCtx.texts, count: imCtx.count}"
        ></ng-container>
      </button>
    </ng-template>
    <ng-template ngbPanelContent>
      <app-impacters-panel *ngIf="article.impacters.length" [impacters]="article.impacters" data-cy="impacters-panel"/>
      <ng-container *ngIf="!article.impacters.length"
                    [ngTemplateOutlet]="nocontent"
                    [ngTemplateOutletContext]="imCtx"
      ></ng-container>
    </ng-template>
  </ngb-panel>

</ngb-accordion>
