<div class="form-source-container container-fluid no-select">

  <div *ngIf="settings" class="settings-row">
    <div class="issuers-container total-step-width">
      <div class="issuers-group" data-cy="source-step-create-alert-issuers-group" *ngFor="let section of settings.sourceStepSections | keyvalue: originalOrder">

        <div class="card-label">{{section.value.sectionTitle | translate}}</div>
        <ng-container *ngIf="section.value.issuerGroups">
          <div *ngFor="let issuerGroup of section.value.issuerGroups | keyvalue: originalOrder">

            <ngb-accordion *ngIf="issuerGroup.value.isAccordion"
                           class="issuer-group-container {{issuerGroup.value.issuerTemplateClassName || ''}}"
                           data-cy="source-step-create-alert-issuer-group-container"
                           id="accordion-{{issuerGroup.value.name}}"
                           [activeIds]="activeId"
                           [closeOthers]="true"
            >
              <ngb-panel id="{{issuerGroup.value.name}}">
                <ng-template *ngIf="issuerGroup.value.isAccordion"
                             ngbPanelHeader let-opened="opened">

                  <ul class="territory-kinds d-table-cell align-middle">
                    <li>
                      <label class="switch checkbox">
                        <input
                          id="input-slider-{{issuerGroup.value.name}}"
                          attr.data-cy="source-step-create-alert-input-slider-{{issuerGroup.value.name}}"
                          type="checkbox"
                          [checked]="issuerGroup.value.checked"
                          [(ngModel)]="issuerGroup.value.checked"
                          (ngModelChange)="onToggleIssuerGroup(issuerGroup.value)"
                          track
                          eventAction="click"
                          [eventType]="EventTypeName.FILTER_CHANGE"
                          [eventLocation]="'alert'"
                          [eventName]="SourceTrackingName.territoryKind"
                          [eventValue]="issuerGroup.value.checked"
                          [eventDetail]="issuerGroup.value.name">
                        <span class="slider round" id="input-slider-round-{{issuerGroup.value.name}}"></span>
                      </label>
                      <div class="d-flex flex-column">
                        <div class="d-flex">
                          <div class="name ms-3 d-flex">{{issuerGroup.value.title | translate}}</div>
                          <div class="d-flex align-items-center badge-active"
                               *ngIf="issuerGroup.value.treeviewItems && issuerGroupBadgeNumber(issuerGroup.value) && issuerGroup.value.checked"
                               [ngClass]="{'empty-badge': !issuerGroup.value.treeviewItems[0]?.getSelection()?.checkedItems?.length}">

                            <div class="align-self-center">{{issuerGroupBadgeNumber(issuerGroup.value)}}</div>
                            <img class="align-self-center" src="assets/images/icon-close.svg"
                                 (click)="resetIssuerGroupTreeviewItems(issuerGroup.value)"
                                 alt="icon-close"
                                 track eventAction="click"
                                 [eventType]="EventTypeName.FILTER_CHANGE"
                                 [eventLocation]="'alert'"
                                 [eventName]="issuerGroup.value.name"
                                 [eventValue]="'reset'"/>
                          </div>
                        </div>
                        <div class="ms-3 issuer-group-subtitle fst-italic">{{issuerGroup.value.subTitle | translate}}</div>
                      </div>

                      <button ngbPanelToggle *ngIf="issuerGroup.value.isAccordion"
                              (click)="onAccordionToggle(issuerGroup.value)"
                              id="accordion-toggle-{{issuerGroup.value.name}}"
                              class="open-panel-button flex-fill btn btn-link d-flex">
                        <img *ngIf="!opened" src="assets/images/icon-chevron-down.svg"
                             alt="icon-chevron-down" class="ms-auto"/>
                        <img *ngIf="opened" src="assets/images/icon-chevron-up.svg"
                             alt="icon-chevron-up" class="ms-auto"/>
                      </button>
                    </li>
                  </ul>

                  <ng-template ngbPanelContent *ngIf="issuerGroup.value.isAccordion" class="simple-text">
                    <ngx-treeview *ngIf="issuerGroup.value.containsTreeview"
                                  [itemTemplate]="itemTemplate"
                                  [config]="issuerGroup.value.config"
                                  [items]="issuerGroup.value.treeviewItems"
                                  (selectedChange)="onSelectedItemChanged(issuerGroup.value, $event)">
                    </ngx-treeview>
                  </ng-template>

                </ng-template>

              </ngb-panel>
            </ngb-accordion>

            <div *ngIf="!issuerGroup.value.isAccordion"
                 id="{{issuerGroup.value.name}}"
                 class="no-accordion-card-header issuer-group-container d-table w-100
                      {{issuerGroup.value.issuerTemplateClassName}}">
              <ul class="territory-kinds d-table-cell align-middle">
                <li class="">
                  <label class="switch checkbox">
                    <input
                      id="input-slider-{{issuerGroup.value.name}}"
                      type="checkbox"
                      [checked]="issuerGroup.value.checked"
                      [(ngModel)]="issuerGroup.value.checked"
                      (ngModelChange)="onToggleIssuerGroup(issuerGroup.value)"
                      track
                      eventAction="click"
                      [eventType]="EventTypeName.FILTER_CHANGE"
                      [eventLocation]="'alert'"
                      [eventName]="SourceTrackingName.territoryKind"
                      [eventValue]="issuerGroup.value.checked"
                      [eventDetail]="issuerGroup.value.name">
                    <span class="slider round"></span>
                  </label>
                  <div class="d-flex flex-column">
                    <div class="name ms-3 d-flex">
                      <div class="">{{issuerGroup.value.title | translate}}</div>
                    </div>
                    <div class="ms-3 issuer-group-subtitle fst-italic">{{issuerGroup.value.subTitle | translate}}</div>
                  </div>
                </li>
              </ul>
            </div>


          </div>

        </ng-container>

      </div>
    </div>
  </div>
</div>

<ng-template #itemTemplate
             let-item="item"
             let-onCollapseExpand="onCollapseExpand"
             let-onCheckedChange="onCheckedChange">
  <div class="row-item"
       [ngClass]="{'root-item': item.isRoot}">
    <div class="left-group" (click)="onCollapseExpand()">
      <i *ngIf="item.children && !item.isRoot"
         aria-hidden="true"
         [ngSwitch]="item.collapsed">
        <img *ngSwitchCase="true" src="assets/images/arrow-ios-right.svg" alt="arrow-ios-right"/>
        <img *ngSwitchCase="false" src="assets/images/arrow-ios-down.svg" alt="arrow-ios-down"/>
      </i>
      <i *ngIf="item.isRoot" class="ps-3">
      </i>
      <div *ngIf="!item.children && !item.isRoot" class="treeview-long-path"></div>
      <div *ngIf="!item.isRoot" class="treeview-path"></div>
    </div>
    <div class="d-flex form-check" [ngClass]="{'node-root': item.isRoot}">
      <label class="align-self-center checkbox"
             [ngClass]="{'last-node': !item.children}">
        <input type="checkbox" class="form-check-input"
               [checked]="item.checked"
               [(ngModel)]="item.checked"
               (change)="onCheckedChange()"
               [disabled]="item['disabled'] || !settings?.sourceStepSections?.get('admin-docs')?.issuerGroups?.get(item.infos?.type)?.checked"
               [indeterminate]="item.indeterminate"
               track
               eventAction="click"
               [eventType]="EventTypeName.FILTER_CHANGE"
               [eventName]="SourceTrackingName.syndicate"
               [eventLocation]="'alert'"
               [eventValue]="item.checked"
               [eventDetail]="item.text"/>

        <span class="checkbox-text"
              [ngClass]="{'text-line-through': item.value &&
                                               (item.infos?.type === 'syndicates' && !validSyndicates?.includes(item.value)) ||
                                               !settings?.sourceStepSections?.get('admin-docs')?.issuerGroups?.get(item.infos?.type)?.checked }"
              ngbTooltip="{{'filter.no-city-in-syndicate' | translate}}"
              placement="right" container="body"
              [disableTooltip]="!(item.value && !validSyndicates?.includes(item.value)) ||
                                item.infos?.type !== 'syndicates'">
          {{item.text}} {{!item.isRoot && !item.value ? '(' + countChecked(item) + '/' + item.children?.length + ')' : ''}}
        </span>

        <span class="checkmark"
              ngbTooltip="{{'alert.tooltip-all-select' | translate}}"
              placement="auto"
              container="body"
              [disableTooltip]="!item.isRoot"></span>
        <img *ngIf="item.infos?.fullname"
             src="assets/images/icon-info-filled.svg"
             class="filter-basic-500"
             alt="full-name-icon"
             ngbTooltip="{{item.infos['fullname']}}"
             placement="auto"
             container="body"/>
      </label>

    </div>
  </div>
</ng-template>
