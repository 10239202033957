'use strict';

const Stringify = require('./stringify');
const internals = {};
module.exports = class extends Error {
  constructor(args) {
    const msgs = args.filter(arg => arg !== '').map(arg => {
      return typeof arg === 'string' ? arg : arg instanceof Error ? arg.message : Stringify(arg);
    });
    super(msgs.join(' ') || 'Unknown error');
    if (typeof Error.captureStackTrace === 'function') {
      // $lab:coverage:ignore$
      Error.captureStackTrace(this, exports.assert);
    }
  }
};