<div class="filter">
  <div class="filter-content">
    <ngb-accordion
      *ngIf="!inDocumentView"
      [closeOthers]="false"
      [activeIds]="['static-1', 'static-3']">
      <ngb-panel id="static-0" *ngIf="currentPage === 'impacter'">
        <ng-template ngbPanelHeader let-opened="opened">
          <button ngbPanelToggle class="btn btn-link container-fluid text-left">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="align-items-center">{{'filter.roles' | translate}}</div>
                <div class="d-flex align-items-center badge-active" *ngIf="badgeNumber(filter['titles'], titlesStore)"
                     [ngClass]="{'empty-badge': !filter['titles'].length}">
                  <div class="align-self-center">{{badgeNumber(filter['titles'], titlesStore)}}</div>
                  <img class="align-self-center" src="assets/images/icon-close.svg" (click)="resetTitles($event)" alt="icon-close"/>
                </div>
              </div>
              <img *ngIf="!opened" src="assets/images/icon-chevron-down.svg" alt="icon-chevron-down"/>
              <img *ngIf="opened" src="assets/images/icon-chevron-up.svg" alt="icon-chevron-up"/>
            </div>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-template #itemTemplate let-roleItem="item" let-onCollapseExpand="onCollapseExpand"
                       let-onCheckedChange="onCheckedChange">
            <div class="row-item" [ngClass]="{'root-item': roleItem.isRoot}">
              <div class="left-group">
                <i *ngIf="roleItem.children && !roleItem.isRoot" (click)="onCollapseExpand()" aria-hidden="true" [ngSwitch]="roleItem.collapsed">
                  <img *ngSwitchCase="true" src="assets/images/arrow-ios-right.svg" alt="arrow-ios-right"/>
                  <img *ngSwitchCase="false" src="assets/images/arrow-ios-down.svg" alt="arrow-ios-down"/>
                </i>
                <i *ngIf="roleItem.isRoot" class="ps-3">
                </i>
                <div *ngIf="!roleItem.isRoot" class="treeview-path"></div>
              </div>
              <div class="form-check" [ngClass]="{'node-root': roleItem.isRoot}">
                <label class="checkbox align-self-center" [ngClass]="{'last-node': !roleItem.children}">
                  <input type="checkbox" class="form-check-input" [checked]="roleItem.checked" [(ngModel)]="roleItem.checked" (change)="onCheckedChange()"
                         [disabled]="roleItem.disabled" [indeterminate]="roleItem.indeterminate" />
                  <span class="checkbox-text">{{'filter.'+roleItem.text | translate}}</span>
                  <span class="checkmark" ngbTooltip="{{'alert.tooltip-all-select' | translate}}"
                        placement="auto" container="body" [disableTooltip]="!roleItem.isRoot"></span>
                </label>
              </div>
            </div>
          </ng-template>
          <ngx-treeview [itemTemplate]="itemTemplate" [config]="config" [items]="functionItems"
                        (selectedChange)="onFunctionChange($event)">
                        <app-simple-loader [isSynchronizedWithLoaderService]="false" [vertical]="true"></app-simple-loader>
          </ngx-treeview>
          <div class="footer px-3">
            <div class="content">
              <div class="text-uppercase role-label">{{'filter.history-roles' | translate}}</div>
              <div class="d-flex">
                <div class="align-self-center">
                  <label class="switch">
                    <input type="checkbox" [(ngModel)]="oldPosition" (ngModelChange)="onOldPositionChange()">
                    <span class="slider round"></span>
                  </label>
                </div>
                <div class="label align-self-center">{{'filter.old-position-tip' | translate}}</div>
              </div>
              <div class="sub-label">{{'filter.old-position-sub-tip' | translate}}</div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="static-1" *ngIf="currentPage === 'admin-doc'">

        <!--global header of issuer section filter-->
        <ng-template ngbPanelHeader let-opened="opened">
          <div [ngClass]="{'opened-issuers-filter-panel-header' : opened,
                                 'issuers-filter-panel-header' : !opened,
                                 'd-flex': true}">
            <button ngbPanelToggle class="issuer-panel-header-btn btn btn-link d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="align-items-center">{{'filter.issuing-authorities' | translate}}</div>
              </div>
              <div class="issuer-chevron btn btn-link d-flex">
                <img *ngIf="!opened" src="assets/images/icon-chevron-down.svg" alt="icon-chevron-down" class="ms-auto"/>
                <img *ngIf="opened" src="assets/images/icon-chevron-up.svg" alt="icon-chevron-up" class="ms-auto"/>
              </div>
            </button>
          </div>
        </ng-template>

        <ng-template ngbPanelContent>

      <div *ngFor="let issuerGroup of adminDocSourceSection | keyvalue: valueAscRank">

        <ngb-accordion
          class="issuers-filter-subsection-container no-select {{issuerGroup.value.issuerTemplateClassName || ''}}"
          data-cy="admin-doc-search-container"
          id="accordion-admin-doc-search-{{issuerGroup.value.name}}"
          [activeIds]="activeId"
          [closeOthers]="true"
        >
          <ngb-panel id="{{issuerGroup.value.name}}">
            <ng-template ngbPanelHeader let-opened="opened"
                         class="issuers-filter-subsection-header-container"
                        >

              <!--header of the accordion of a group of issuers-->
              <ul class="issuers-kinds-items w-100 d-table">
                <li>
                  <!--switch pour sélectionner le groupe d'émetteur-->
                  <label class="switch checkbox group-input-switch d-table-cell align-middle"
                        [ngClass]="{'empty-selection' : !issuerGroup.value.treeviewItems[0]?.getSelection()?.checkedItems?.length}">
                    <input
                      id="input-slider-{{issuerGroup.value.name}}"
                      attr.data-cy="input-slider-{{issuerGroup.value.name}}"
                      type="checkbox"
                      [checked]="issuerGroup.value.checked"
                      [(ngModel)]="issuerGroup.value.checked"
                      (ngModelChange)="onCheckBoxIssuerGroup(issuerGroup.value)"
                      track
                      eventAction="click"
                      [eventType]="EventTypeName.FILTER_CHANGE"
                      [eventLocation]="'search'"
                      [eventName]="SourceTrackingName.territoryKind"
                      [eventValue]="issuerGroup.value.checked"
                      [eventDetail]="issuerGroup.value.name">
                    <span class="slider round" id="input-slider-round-{{issuerGroup.value.name}}"
                          [attr.data-cy]="'input-slider-round-' + issuerGroup.value.name"
                    ></span>
                  </label>
                  <!--nom du groupe d'émetteur-->
                  <div class="flex-fill issuer-group-name-container d-table-cell align-middle">
                    <div class="w-100 d-flex">
                      <!--issuer group name-->
                      <div class="issuer-group-name name d-flex"
                           id="issuer-group-name-{{issuerGroup.value.name}}"
                           (click)="onAccordionToggle($event, issuerGroup.value)">{{issuerGroup.value.title | translate}}</div>
                      <!--badge of #selected item / #total items-->
                      <div class="d-flex align-items-center badge-active"
                           *ngIf="issuerGroup.value.treeviewItems && issuerGroupBadgeNumber(issuerGroup.value) && issuerGroup.value.checked"
                           [ngClass]="{'empty-badge': !issuerGroup.value.treeviewItems[0]?.getSelection()?.checkedItems?.length}">
                        <div class="align-self-center">{{issuerGroupBadgeNumber(issuerGroup.value)}}</div>
                        <img class="align-self-center" src="assets/images/icon-close.svg"
                             (click)="resetIssuerGroupTreeviewItems(issuerGroup.value)"
                             alt="icon-close"
                             track eventAction="click"
                             [eventType]="EventTypeName.FILTER_CHANGE"
                             [eventLocation]="'alert'"
                             [eventName]="issuerGroup.value.name"
                             [eventValue]="'reset'"/>
                      </div>
                    </div>
                  </div>
                  <!--chevron used to open / close panel-->
                  <button (click)="onAccordionToggle($event, issuerGroup.value)"
                          id="accordion-toggle-{{issuerGroup.value.name}}"
                          [attr.data-cy]="'accordion-toggle-' + issuerGroup.value.name"
                          class="open-panel-button btn btn-link d-table-cell align-middle">
                    <img *ngIf="!opened" src="assets/images/icon-chevron-down.svg"
                         alt="icon-chevron-down" class="ms-auto"/>
                    <img *ngIf="opened" src="assets/images/icon-chevron-up.svg"
                         alt="icon-chevron-up" class="ms-auto"/>
                  </button>
                </li>
              </ul>
            </ng-template>

            <ng-template ngbPanelContent class="simple-text">
              <ngx-treeview [itemTemplate]="itemTemplate"
                            [config]="issuerGroup.value.config"
                            [items]="issuerGroup.value.treeviewItems"
                            (selectedChange)="onSelectedIssuerTreeviewItemChanged($event, issuerGroup.value)">
              </ngx-treeview>
            </ng-template>

          </ngb-panel>
        </ngb-accordion>
      </div>

      </ng-template>

      </ngb-panel>
      <ngb-panel id="static-3" >
        <ng-template ngbPanelHeader let-opened="opened">
          <button ngbPanelToggle class="btn btn-link container-fluid text-left">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="align-items-center">{{'filter.geographic-scope' | translate}}</div>
                <div class="d-flex align-items-center badge-active" *ngIf="badgeNumber(selectedTerritories, territoriesStore)"
                     [ngClass]="{'empty-badge': !selectedTerritories.length}">
                  <div class="align-self-center">{{badgeNumber(selectedTerritories, territoriesStore)}}</div>
                  <img class="align-self-center" src="assets/images/icon-close.svg" (click)="resetTerritories($event)" alt="icon-close"
                       track eventAction="click" [eventType]="EventTypeName.FILTER_CHANGE"
                       [eventName]="FilterTrackingName.perimeter" [eventLocation]="currentPage"
                       [eventValue]="'reset'"/>
                </div>
              </div>
              <img *ngIf="!opened" src="assets/images/icon-chevron-down.svg" alt="icon-chevron-down"/>
              <img *ngIf="opened" src="assets/images/icon-chevron-up.svg" alt="icon-chevron-up"/>
            </div>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-template #itemTemplate let-territoryItem="item" let-onCollapseExpand="onCollapseExpand"
                       let-onCheckedChange="onCheckedChange">
            <div class="row-item" [ngClass]="{'hidden': hideItem(territoryItem), 'root-item': territoryItem.isRoot}">
              <div class="left-group">
                <i *ngIf="territoryItem.children && !territoryItem.isRoot" (click)="onCollapseExpand()" aria-hidden="true" [ngSwitch]="territoryItem.collapsed">
                  <img *ngSwitchCase="true" src="assets/images/arrow-ios-right.svg" alt="arrow-ios-right"/>
                  <img *ngSwitchCase="false" src="assets/images/arrow-ios-down.svg" alt="arrow-ios-down"/>
                </i>
                <i *ngIf="territoryItem.isRoot" (click)="collapse()" aria-hidden="true" [ngSwitch]="territoryItem.isRoot"
                   ngbTooltip="{{'alert.tooltip-collapse' | translate}}" placement="auto" container="body">
                  <ng-container *ngSwitchCase="true">
                    <img src="assets/images/icon-expand.svg" alt="icon-expand"/>
                  </ng-container>
                  <ng-container *ngSwitchCase="false" [ngSwitch]="territoryItem.collapsed">
                    <img *ngSwitchCase="true" src="assets/images/arrow-ios-right.svg" alt="arrow-ios-right"/>
                    <img *ngSwitchCase="false" src="assets/images/arrow-ios-down.svg" alt="arrow-ios-down"/>
                  </ng-container>
                </i>
                <div *ngIf="!territoryItem.isRoot" class="treeview-path"></div>
              </div>
              <div class="form-check" [ngClass]="{'node-root': territoryItem.isRoot}">
                <label  class="align-self-center"
                        [ngClass]="{'checkbox' : !territoryItem.outside_department_count,'last-node': !territoryItem.children}">

                  <input *ngIf="!territoryItem.outside_department_count" type="checkbox" class="form-check-input"
                         [checked]="territoryItem.checked" [(ngModel)]="territoryItem.checked" (change)="onCheckedChange();"
                         [disabled]="territoryItem.disabled" [indeterminate]="territoryItem.indeterminate"
                         track eventAction="click" [eventType]="EventTypeName.FILTER_CHANGE"
                         [eventName]="FilterTrackingName.perimeter" [eventLocation]="currentPage"
                         [eventValue]="territoryItem.checked" [eventDetail]="territoryItem.value"/>
                  <img *ngIf="territoryItem.outside_department_count" class="icon-info"
                       src="assets/images/info.svg" alt="arrow-ios-right"/>


                  <span *ngIf="territoryItem.value"
                        class="checkbox-text"
                        [ngClass]="{'text-line-through': (currentPage == 'admin-doc' && counter(territoryItem) === 0) && showCounter(territoryItem)}"
                        ngbTooltip="{{'filter.no-document-in-city' | translate}}"
                        placement="right" container="body" [disableTooltip]="!((currentPage == 'admin-doc' && counter(territoryItem) === 0) && showCounter(territoryItem))"
                        [innerHTML]="territoryItem.text | emphasizeFilter: filterText">
                  </span>

                  <span *ngIf="territoryItem.text === 'outside_Metropolis_Greater_Paris'" class="checkbox-text info-content writing-size"> {{territoryItem.outside_department_count}} {{'filter.metropolis_of_Greater_Paris_content'| translate}}</span>
                  <span *ngIf="territoryItem.text !== 'outside_Metropolis_Greater_Paris' && territoryItem.outside_department_count === 1" class="checkbox-text info-content writing-size"> {{territoryItem.outside_department_count}} {{'filter.outside_department_content'| translate}}</span>
                  <span *ngIf="territoryItem.text !== 'outside_Metropolis_Greater_Paris' && territoryItem.outside_department_count > 1" class="checkbox-text info-content writing-size"> {{territoryItem.outside_department_count}} {{'filter.outside_department_contents'| translate}}</span>

                  <span *ngIf="!territoryItem.value && !territoryItem.outside_department_count" class="checkbox-text text-uppercase">{{('filter.' + territoryItem.text) | translate}}</span>
                  <span class="{{!territoryItem.outside_department_count ? 'checkmark': ''}}" ngbTooltip="{{'alert.tooltip-all-select' | translate}}"
                        placement="auto" container="body" [disableTooltip]="!territoryItem.isRoot"></span>
                </label>
              </div>
              <ng-container *ngIf="showCounter(territoryItem) && !(currentPage == 'admin-doc' && counter(territoryItem) === 0)">
                <div *ngIf="!inSearch && !inImpacterView" class="counter" [ngClass]="{'checked': territoryItem.checked != false}">{{counter(territoryItem) | insertText: ' ': 3}}</div>
                <div *ngIf="(inImpacterView || inSearch) && territoryItem.infos['searchCount'] != undefined" class="counter" [ngClass]="{'checked': territoryItem.checked != false}">
                  {{counterBySearch(territoryItem) | insertText: ' ': 3}}
                </div>
              </ng-container>
            </div>
          </ng-template>
          <ng-template #headerTemplate let-config="config" let-onFilterTextChange="onFilterTextChange">
            <div *ngIf="config.hasFilter" class="filter-search-container">
                <input class="form-control location-search" type="text" [placeholder]="'filter.search-placeholder' | translate" [(ngModel)]="filterText"
                       (ngModelChange)="onFilterTextChange($event)"/>
                <img *ngIf="!filterText" src="/assets/images/search.svg" class="icon-filter icon-filter--search" alt="search-icon"/>
                <img *ngIf="filterText" src="/assets/images/icon-close-circle-fill.svg" class="icon-filter icon-filter--close" (click)="onFilterTextChange(''); filterText=''" alt="clear-icon"/>
            </div>
          </ng-template>
          <ngx-treeview [itemTemplate]="itemTemplate" [headerTemplate]="headerTemplate" [config]="config" [items]="items" [textChange]="filterText"
                        (selectedChange)="onSelectedTerritoryChange($event)">
                        <app-simple-loader [isSynchronizedWithLoaderService]="false" [vertical]="true"></app-simple-loader>
          </ngx-treeview>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</div>

<ng-template #itemTemplate
             let-item="item"
             let-onCollapseExpand="onCollapseExpand"
             let-onCheckedChange="onCheckedChange">
  <div class="row-item"
       [ngClass]="{'root-item': item.isRoot}">
    <div class="left-group" (click)="onCollapseExpand()">
      <i *ngIf="item.children && !item.isRoot"
         aria-hidden="true"
         [ngSwitch]="item.collapsed">
        <img *ngSwitchCase="true" src="assets/images/arrow-ios-right.svg" alt="arrow-ios-right"/>
        <img *ngSwitchCase="false" src="assets/images/arrow-ios-down.svg" alt="arrow-ios-down"/>
      </i>
      <i *ngIf="item.isRoot" class="ps-3">
      </i>
      <div *ngIf="!item.children && !item.isRoot" class="treeview-long-path"></div>
      <div *ngIf="!item.isRoot" class="treeview-path"></div>
    </div>
    <div class="d-flex form-check" [ngClass]="{'node-root': item.isRoot}">
      <label class="align-self-center checkbox"
             [ngClass]="{'last-node': !item.children}">
        <input type="checkbox" class="form-check-input"
               [checked]="item.checked"
               [(ngModel)]="item.checked"
               (change)="onCheckedChange()"
               [disabled]="!!item.disabled || !adminDocSourceSection.get(item.infos?.type)?.checked"
               [indeterminate]="item.indeterminate"
               track
               eventAction="click"
               [eventType]="EventTypeName.FILTER_CHANGE"
               [eventName]="SourceTrackingName.syndicate"
               [eventLocation]="'alert'"
               [eventValue]="item.checked"
               [eventDetail]="item.text"/>

        <span class="checkbox-text"
              data-cy="admin-doc-issuer-group-checkbox"
              [ngClass]="{'text-line-through': item.value &&
                                               (item.infos?.type === 'syndicates' && !validSyndicates?.includes(item.value)) ||
                                               !adminDocSourceSection.get(item.infos?.type)?.checked }"
              ngbTooltip="{{'filter.no-city-in-syndicate' | translate}}"
              placement="right" container="body"
              [disableTooltip]="!(item.value && !validSyndicates?.includes(item.value)) ||
                                item.infos?.type !== 'syndicates'"
        >{{item.text}} {{!item.isRoot && !item.value ? '(' + countChecked(item) + '/' + item.children?.length + ')' : ''}}
        </span>

        <span class="checkmark"
              ngbTooltip="{{'alert.tooltip-all-select' | translate}}"
              placement="auto"
              container="body"
              [disableTooltip]="!item.isRoot"></span>
        <img *ngIf="item.infos?.fullname"
             src="assets/images/icon-info-filled.svg"
             class="filter-basic-500"
             alt="full-name-icon"
             ngbTooltip="{{item.infos['fullname']}}"
             placement="auto"
             container="body"/>
      </label>

    </div>
  </div>
</ng-template>
