import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss']
})
export class NavigatorComponent implements OnInit {

  public chromeDownload = false;
  public firefoxDownload = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  hover(value: string) {
    if (value === 'chrome') {
      this.chromeDownload = true;
    }
    if (value === 'firefox') {
      this.firefoxDownload = true;
    }
  }

  out(value: string) {
    if (value === 'chrome') {
      this.chromeDownload = false;
    }
    if (value === 'firefox') {
      this.firefoxDownload = false;
    }
  }

  openFirefox() {
    window.open('https://www.mozilla.org/en-GB/');
  }

  openChrome() {
    window.open('https://chromeenterprise.google/browser/download/');
  }
}
