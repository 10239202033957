import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { firstValueFrom, forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../../models/user';
import { MatExpansionPanel } from '@angular/material/expansion';
import { email } from '@sideway/address';
import { ApiService } from '../../../shared/services/api/api.service';
import { SharedDocument } from '../../../models/share/share';
import { UserTrackerService } from '../../../shared/services/tracking/user-tracker.service';
import { EventTypeName } from '../../../models/user-tracker';
import { ToastMessageStackService } from '../../../shared/services/toast-message-stack.service';
import { ExplainModuleEnum } from "../../../shared/services/module-manager.service";
@Component({
  selector: 'app-sharing-panel',
  templateUrl: './sharing-panel.component.html',
  styleUrls: ['./sharing-panel.component.scss'],
})
export class SharingPanelComponent implements OnInit {
  addresseeDropdownMenuDisplayed = false;
  shareMessage = '';
  personalInfos = {
    groupAccountId: +(localStorage.getItem('group_account_id') ?? 0),
    domainName: localStorage.getItem('email')?.split('@')[1],
    id: +(localStorage.getItem('user_id') ?? 0)
  };
  options: User[] = [];
  filteredOptions$: Observable<User[]> = new Observable<User[]>();
  filteredOptions: User[] = [];
  selectedUsers: User[] = [];
  indexSelected = 0;
  errors = {
    email: false,
    duplicatedEmail: false
  };
  inputFocus = false;

  isContactListSelected = false;
  sendCopy = false;
  newUser?: User;
  inputText = '';
  indexAddressToEdit = -1;
  @Input({required: true}) document!: SharedDocument;
  @ViewChild('sharePanel') sharePanel!: MatExpansionPanel;
  @ViewChild('autoInput') input!: ElementRef;
  @ViewChild('edit') editInput!: ElementRef;
  @ViewChild('users') userTable!: ElementRef;
  @ViewChild('tooltipCreditLeft') tooltipCreditLeft!: TemplateRef<any>;
  @ViewChild('tooltipNoCreditLeft') tooltipNoCreditLeft!: TemplateRef<any>;
  @ViewChild('tooltipCreditUnavailable') tooltipCreditUnavailable!: TemplateRef<any>;
  @ViewChild('sharingToast', { read: TemplateRef }) sharingToastTpl!: TemplateRef<any>;
  @Output() isPanelOpen: EventEmitter<boolean>;
  @Input() withExportPanel: boolean = false;

  constructor(
    private apiService: ApiService,
    private toastMessageStackService: ToastMessageStackService,
    private userTrackerService: UserTrackerService
  ) {
    this.isPanelOpen = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
    this.getUsers();
  }

  onSubmit() {
    firstValueFrom(this.apiService.share.insertShare(this.document, this.shareMessage, this.selectedUsers.map(user => user.email), this.sendCopy))
      .then(() => {
        this.toastMessageStackService.show(this.sharingToastTpl, {autohide: true, classname: 'success-toast toast-shape'});
        this.onClosePanel();
        this.trackSharing();
        this.onReset();
      }, error => {
        console.log(error);
      });
  }
  trackSharing() {
    const trigger_event_timestamp = (new Date()).toISOString();
    const sharingUserId = localStorage.getItem('user_id');
    const sharingUserEmail = localStorage.getItem('email');
    const body =
      this.selectedUsers.map((user) => ({
        event_type: EventTypeName.DOCUMENT_SHARING,
        event_timestamp: trigger_event_timestamp,
        sharing_user_id: sharingUserId,
        sharing_user_email: sharingUserEmail,
        shared_with_user: {
          id: user.id,
          email: user.email,
          level: user.userLevel.level,
        },
        send_copy: this.sendCopy,
        document_type: this.document.type,
        document_uid: this.document.documentUid,
      }));
    this.userTrackerService.track(body).toPromise();
  }

  filterOptions() {
    // remove self user and duplicates from the array based on id
    this.options = this.options.filter((value, index, self) => {
      return index === self.findIndex((t) => (
        t.id === value.id && t.id !== this.personalInfos.id
      ));
    });
  }

  sortOptions() {
    this.options = this.options.sort((a, b) => {
      if (this.isInSameCompany(a) && a.firstName && this.isInSameCompany(b) && b.firstName) {
        return a.firstName.localeCompare(b.firstName) > 0 ? 1 : -1;
      } else if (this.isInSameCompany(a) && a.firstName) {
        return a.firstName.localeCompare(b.email) > 0 ? 1 : -1;
      } else if (this.isInSameCompany(b) && b.firstName) {
        return a.email.localeCompare(b.firstName) > 0 ? 1 : -1;
      } else {
        return a.email.localeCompare(b.email) > 0 ? 1 : -1;
      }
    });
  }

  // handling logic of addressee's list
  getUsers() {
    const filters = {
      'group_account_id': this.personalInfos.groupAccountId,
      'user_level': {
        'level': 'simple_user'
      },
      'is_active': true
    };
    const observables = [
      this.apiService.user.searchUsers(filters),
      this.apiService.share.searchSharedWithUsers()
    ];
    this.options = [];
    forkJoin(observables).subscribe(responseList => {
      for (const response of responseList) {
        this.options = this.options.concat(response);
      }
      this.filterOptions();
      this.sortOptions();
      this.filteredOptions$ = of(this.options);
      this.filteredOptions = this.options;
    });
  }
  searchUser(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    const sanitizedEmail = this.inputText.trim().toLowerCase();
    if (!this.addresseeDropdownMenuDisplayed) {
      this.toggleDropdown(true);
      return;
    }
    if (!this.checkEmailFormat(sanitizedEmail)) {
      const defaultUser = this.getDefaultSelect();
      if (defaultUser) {
        this.onSelectAddressee(defaultUser, undefined);
      } else {
        this.errors.email = true;
      }
    } else {
      const foundUser = this.selectedUsers.find(user => user.email === sanitizedEmail);
      if (foundUser && this.selectedUsers.indexOf(foundUser) === this.indexAddressToEdit ) {
        this.clickOutside();
      } else {
        if (this.checkIfUserSelected(sanitizedEmail)) {
          this.errors.duplicatedEmail = true;
        } else {
          if (this.newUser) this.onSelectAddressee(this.newUser, undefined);
        }
      }
    }
  }

  getDefaultSelect() {
    let defaultSelect = null;
    if (this.filteredOptions.length > this.indexSelected) {
      defaultSelect = this.filteredOptions[this.indexSelected];
    }
    return defaultSelect;
  }

  private filter(value: string): User[] {
    const filterValue = value;
    return this.options.filter(optionValue => {
      return optionValue.firstName?.toLowerCase().includes(filterValue.toLowerCase())
        || optionValue.email.toLowerCase().includes(filterValue.toLowerCase());
    });
  }

  getFilteredOptions(value: string): Observable<User[]> {
    return of(value).pipe(
      map((filterString: string) => this.filter(filterString)),
    );
  }

  onClickPanel(panelExpanded: boolean) {
    this.isPanelOpen.emit(panelExpanded);
  }

  onChange(editMode = false) {
    this.inputText =  editMode  ? this.editInput.nativeElement.value : this.input.nativeElement.value;
    this.filteredOptions$ = this.getFilteredOptions(this.inputText);
    this.filteredOptions$.subscribe(filteredOptions => this.filteredOptions = filteredOptions).unsubscribe();
    const elementsAddresseeList = document.getElementsByClassName('option-item');
    if (elementsAddresseeList.length) {
      elementsAddresseeList[0].scrollIntoView({block: 'nearest', inline: 'nearest'});
    }
    if (this.inputText) {
      this.toggleDropdown(true);
    } else {
      this.newUser = undefined;
      if (this.editInput) {
        this.removeRecipient(this.selectedUsers[this.indexAddressToEdit].email);
        this.indexAddressToEdit = -1;
        this.input.nativeElement.focus();
        return;
      }
    }
    const sanitizedEmail = this.inputText.trim().toLowerCase();
    if (!this.checkIfUserSelected(sanitizedEmail)) {
      this.errors.duplicatedEmail = false;
    }
    if (this.checkEmailFormat(sanitizedEmail)) {
      this.errors.email = false;
    }
    if (!this.checkEmailFormat(sanitizedEmail) || this.checkIfUserSelected(sanitizedEmail)) {
      this.indexSelected = 0;
      this.newUser = undefined;
    } else {
      firstValueFrom(this.apiService.user.searchUsers({email: sanitizedEmail})).then((user: User[]) => {
        let userInput;
        if (user?.length > 0 && user[0].email === sanitizedEmail) {
          userInput = user[0];
          if (!this.isGuestUser(user[0])) {
            userInput.newDest = true;
          }
        } else {
          userInput = new User({
            kind: 'NewUserFromSharingPanel',
            email: sanitizedEmail,
            first_name: "",
            last_name: "",
            user_level: {
              level: 'guest_user',
              max_credit: 20,
              remaining_credits: 20
            },
            group_account_id: this.personalInfos.groupAccountId,
            is_active: true
          });
        }
        this.indexSelected = -1;
        this.newUser = userInput;
      });
    }
    if (editMode) {
      this.editInput.nativeElement.focus();
    }
    if (!this.inputText) {
      this.errors.email = false;
      this.errors.duplicatedEmail = false;
    }
  }

  onSelectAddressee(user: User, event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    this.input.nativeElement.focus();
    this.inputFocus = true;
    if (this.isInSameCompany(user) && this.isGuestUser(user) && user.userLevel.remaining_credits < 1) {
      return;
    }
    if (!this.checkIfUserSelected(user.email)) {
      this.errors.email = false;
      this.errors.duplicatedEmail = false;
      this.input.nativeElement.value = '';
      this.filteredOptions$ = this.getFilteredOptions('');
      this.filteredOptions$.subscribe(filteredOptions => this.filteredOptions = filteredOptions).unsubscribe();
      this.newUser = undefined;
      if (this.indexAddressToEdit >= 0) {
        this.selectedUsers[this.indexAddressToEdit] = user;
      } else {
        this.selectedUsers.push(user);
      }
      this.inputText = '';
      this.indexAddressToEdit = -1;
    } else {
      this.errors.duplicatedEmail = true;
    }
  }

  move(direction: string) {
    if (!this.addresseeDropdownMenuDisplayed) {
      return;
    }
    const maxIndex = this.filteredOptions.length - 1;
    const minIndex = this.newUser ? -1 : 0;
    if (direction === 'down') {
      if (maxIndex > this.indexSelected) {
        this.indexSelected++;
      } else {
        this.indexSelected =  minIndex;
      }
    } else {
      if (this.indexSelected > minIndex) {
        this.indexSelected--;
      } else {
        this.indexSelected = maxIndex;
      }
    }
  }

  // visual logic helpers.
  getCreditTooltip(user: User) {
    if (user.newDest) {
      return null;
    }
    if (this.isInSameCompany(user)) {
      if (this.isSimpleUser(user)) {
        return null;
      } else if (user.userLevel.remaining_credits > 0) {
        return this.tooltipCreditLeft;
      } else {
        return this.tooltipNoCreditLeft;
      }
    } else {
      return this.tooltipCreditUnavailable;
    }
  }

  onClosePanel() {
    this.sharePanel.close();
    this.isPanelOpen.emit(false);
  }

  panelStateUpdate(expanded: boolean) {
    if (expanded === false) {
      this.onReset();
    }
  }

  onReset() {
    if (this.input) {
      this.input.nativeElement.value = '';
    }
    this.selectedUsers = [];
    this.shareMessage = '';
    this.errors = {
      email: false,
      duplicatedEmail: false
    };
    this.filteredOptions$ = this.getFilteredOptions('');
    this.indexSelected = 0;
    this.sendCopy = false;
  }
  toggleDropdown(display: boolean = false, focus = true, event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    if (focus) {
      this.input.nativeElement.focus();
    }
    this.inputFocus = focus;
    if (display !== null) {
      this.addresseeDropdownMenuDisplayed = display;
    } else {
      this.addresseeDropdownMenuDisplayed = !this.addresseeDropdownMenuDisplayed;
    }
  }

  checkEmailFormat(mailAddress: string) {
    const accentedChars = 'ÀàÁáÂâÃãÄäÅåÆæÇçÐðÈèÉéÊêËëÌìÍíÎîÏïÑñÒòÓóÔôÕõÖöœŒØøßÙùÚúÛûÜüÝýÞþŸÿ';
    const addressContainsAccentedChars = accentedChars.split('').some(char => mailAddress.includes(char));
    const endsByDotCO = mailAddress.endsWith('.co');
    return email.isValid(mailAddress) && !addressContainsAccentedChars && !endsByDotCO;
  }

  isGuestUser(user: User) {
    if (user.userLevel.level === 'simple_user') {
      return !(user.modules?.includes(ExplainModuleEnum.TERRITORY_INTEL) && user.isActive);
    }
    return user.userLevel.level === 'guest_user';
  }

  isSimpleUser(user: User) {
    return user.modules?.includes(ExplainModuleEnum.TERRITORY_INTEL) &&
           user.userLevel.level === 'simple_user' &&
           user.isActive;
  }

  isInSameCompany(user: User) {
    const userDomainName = user.email.split('@')[1];
    return user.groupAccountId === this.personalInfos.groupAccountId
      || userDomainName === this.personalInfos.domainName;
  }
  displayOrHideContactList(event: Event) {
    event.stopPropagation();
    this.input.nativeElement.focus();
    this.isContactListSelected = !this.isContactListSelected;
  }

  checkIfUserSelected(mailAddress: string) {
    const sanitizedEmail = mailAddress.trim().toLowerCase();
    return this.selectedUsers.find(u => u.email === sanitizedEmail);
  }
  removeRecipient(mailAddress: string, event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    this.selectedUsers = this.selectedUsers.filter(u => u.email !== mailAddress);
  }
  onSendCopyChange() {
    this.sendCopy = !this.sendCopy;
  }
  checkAddressContainsAtSymbol(mailAddress: string) {
    return  mailAddress.includes('@');
  }
  clickOutside() {
    if (this.inputText) {
      if (!this.newUser) {
        this.input.nativeElement.value = '';
        if (this.editInput) { this.editInput.nativeElement.value = ''; }
        this.errors.email = false;
        this.inputText = '';
        this.indexAddressToEdit = -1;
      } else {
        this.onSelectAddressee(this.newUser);
      }
    }
    this.indexAddressToEdit = -1;
    this.toggleDropdown(false, false);
  }
  onKeyDown(event: KeyboardEvent) {
    if (event.keyCode === 8) { // check if backspace button was pressed
      if ( this.inputText.length === 0) {
        this.removeLastRecipient();
      }
    }
  }
  removeLastRecipient() {
    if (!this.inputText && this.selectedUsers.length > 0) {
      this.removeRecipient(this.selectedUsers[this.selectedUsers.length - 1].email);
    }
  }
  changeEditMode(user: User, index: number, event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    this.indexAddressToEdit = index;
    this.inputText = user.email;
    setTimeout(() => {
      this.editInput.nativeElement.focus();
    }, 10);
  }
}
