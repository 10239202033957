<app-header [showContent]="true" [territoryUid]="territoryUid"></app-header>
<div class="page-content">

  <app-filter class="filter-panel" [hidden]="entityId"
              [inDocumentView]="!!entityId"
              [currentPage]="currentPage"
              [territoryUid]="territoryUid">
  </app-filter>

  <div class="right-content" [ngClass]="{'width-fix': !entityId}">
    <div class="w-100">
      <div *ngIf="!entityId" [ngClass]="{'container-bottom-line': currentPage !== 'impacter'}">
        <div *ngIf="currentPage === 'article' || currentPage === 'admin-doc'" class="search-tabs-container">
          <div class="search-tab"
               [ngClass]="{'selected-tab' : SearchModeMap.get(publicationSource) === SearchMode.TOPICS }"
               (click)="onChangeTab(SearchMode.TOPICS)">{{'search.search-by-topic' | translate | uppercase }}
          </div>
          <div class="search-tab" [ngClass]="{'selected-tab' : SearchModeMap.get(publicationSource) === SearchMode.TEXT }" data-cy="keyword-search"
               (click)="onChangeTab(SearchMode.TEXT)">{{'search.search-by-text' | translate | uppercase}}
          </div>
        </div>
      </div>
    </div>

    <app-search *ngIf="!entityId && currentPage !== 'impacter' && SearchModeMap.get(publicationSource) === SearchMode.TOPICS" [publicationSource]=publicationSource
                [currentPage]="currentPage"
                [haveTip]="false"></app-search>

    <app-search *ngIf="!entityId && currentPage !== 'impacter' && SearchModeMap.get(publicationSource) === SearchMode.TEXT" [publicationSource]=publicationSource
                [currentPage]="currentPage"
                [haveTip]="true"></app-search>

    <app-search *ngIf="!entityId && currentPage === 'impacter'" [currentPage]="currentPage"
                [haveTip]="false"></app-search>

    <app-task-bar [hidden]="entityId || !totalCount" [currentPage]="currentPage"
                  [resultCount]="totalCount"
                  [allCount]="allCount"
                  [nbResource]="nbResource"
                  [sourcesList]="sourcesList"
    ></app-task-bar>

    <div class="list-entities">
      <router-outlet></router-outlet>
    </div>

  </div>
</div>
