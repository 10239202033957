import { PipeTransform, Pipe } from '@angular/core';
import { SearchHelper } from '../helpers/search.helper';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(text: string, searchText: string): any {
    if (!searchText) {
      return text;
    }
    return text.replace(
      SearchHelper.regexpPattern(searchText),
      (str) => `<em>${str}</em>`
    );
  }
}
