import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[ngbTooltip][appShowIfTruncated]'
})
export class ShowIfTruncatedDirective {

  constructor(
    private ngbTooltip: NgbTooltip,
    private elementRef: ElementRef<HTMLElement>
  ) {}

  @HostListener('mouseenter', ['$event'])
  setTooltipState(): void {
      const element = this.elementRef.nativeElement;
      this.ngbTooltip.disableTooltip = element.scrollWidth <= element.offsetWidth;
  }
}
