import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityService } from '../../../shared/services/entity.service';
import { ControlFService } from '../../../shared/services/control-f.service';
import { first } from 'rxjs/operators';
import Utils from '../../../shared/utils';
import { ContextService } from '../../../shared/services/context.service';
import { Article } from '../../../models/article';
import { ApiService } from '../../../shared/services/api/api.service';
import { HttpParams } from '@angular/common/http';
import { EventTypeName } from '../../../models/user-tracker';
import { DocumentType } from '../../../models/document-entity';
import { SharedDocument } from '../../../models/share/share';
import { territoriesToUIDArray } from '../../../shared/helpers/territory.helper';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { TerritoryCounters } from '../../../models/territory/territory-counters';
import { UserTrackerService } from '../../../shared/services/tracking/user-tracker.service';

@Component({
  selector: 'app-article-view',
  templateUrl: './article-view.component.html',
  styleUrls: ['./article-view.component.scss'],
})
export class ArticleViewComponent implements OnInit, OnDestroy {
  @ViewChild('articleView') articleViewElement!: ElementRef;
  articleId!: string;
  article!: Article;
  query!: string;
  currentNavigationState?: { [k: string]: any; };
  private impacterId!: string;
  public documentProviderId = '';
  type = DocumentType.ARTICLE;
  trackingEventName: EventTypeName | '' = '';
  documentToBeShared!: SharedDocument;
  referenceTerritories!: Array<string>;
  referenceTerritoriesCounters?: TerritoryCounters;

  hoveredImpacterId$ = new BehaviorSubject<string|undefined>(undefined);
  impactersStyle!: SafeHtml;

  // Management of the view from folder view
  folderId!: string;
  inSearch = false;
  searchNotFound = false;

  show = false;

  constructor(
    private route: ActivatedRoute,
    private entityService: EntityService,
    private apiService: ApiService,
    private _controlFService: ControlFService,
    private contextService: ContextService,
    private utils: Utils,
    private router: Router,
    private sanitizer: DomSanitizer,
    private userTrackerService: UserTrackerService
  ) {
    this.currentNavigationState = this.router.getCurrentNavigation()?.extras?.state;
  }

  ngOnInit(): void {

    this._controlFService.getLoaderAttributes().subscribe((loader: any) => {
      this.show = loader['show'];
      this.inSearch = loader['inSearch'];
      this.searchNotFound = loader['searchNotFound'];
    });
    this._controlFService.setSearchTimeout();

    this._controlFService.resetInputSearch();

    const openSource = this.route.snapshot.data['openSource'];
    const routeQueryParams = this.route.snapshot.queryParams;
    if (openSource === 'digest' && routeQueryParams['rank']) {
      this.trackingEventName = EventTypeName.DOCUMENT_OPENING_FROM_ALERT;
    }
    const params: any = this.utils.getAllParamsFromRoute(this.route);
    this.articleId = params['articleId'];
    this.folderId = params['folderId'];
    if (this.articleId) {
      this.entityService.sendEntityId(this.articleId);
      this.fetchData(params['territoryUid'], openSource, params['openSourceId'], params['collectiveOrderId']).then();
    }
    this.impacterId = params['impacterId'];
    // To manage context on alert / share
    if (params['openSourceId']) {
      this.storeSnippet(openSource, params['openSourceId'], params['collectiveOrderId']).then();
    }
    this.hoveredImpacterId$.subscribe((value) => this.updateImpactersStyle(value));
  }

  ngOnDestroy(): void {
    this.entityService.sendEntityId(this.impacterId);
    this.entityService.sendDocument(null);

    this._controlFService.resetInputSearch();

    this._controlFService.clearInterval();
    this.hoveredImpacterId$.complete();
  }

  get controlFService() {
    return this._controlFService;
  }

  private updateImpactersStyle (key?: string) {
    // Angular does not permit style tags in the template, so we bypass it as it is not a security
    // issue in our case.
    const imClass = '.im-' + (key ?? 'none');
    this.impactersStyle = this.sanitizer.bypassSecurityTrustHtml(
      `<style>
        ${imClass}, ${imClass} .im-color {
          color: var(--primary-500) !important
        }
        ${imClass} .im-color img {
          filter: var(--filter-primary-500)
        }
        ${imClass} .positive-count {
           color: white !important;
           background-color: var(--primary-500) !important;
        }
        ${imClass}.im-background {
          background-color: var(--basic-200)
        }
      </style>`
    );
  }

  @HostListener('mouseover', ['$event.target'])
  onHover(target: HTMLElement) {
    const classes = target.getAttribute('class') ?? '';
    const key = /^.*im-(?<key>\d+).*/.exec(classes)?.groups?.['key']
    this.hoveredImpacterId$.next(key);
  }

  @HostListener('click', ['$event.target'])
  onClick(target: any) {
    const classes = target.getAttribute('class');
    if(classes) {
      const key = /^.*im-(?<key>\d+).*/.exec(classes)?.groups?.['key'];
      const userId = localStorage.getItem('user_id');
      if (key && userId) {
        const impacter = this.article.impacters.find(im => im.id === +key);
        this.userTrackerService.track({
          event_type: EventTypeName.IMPACTER_OPENING_FROM_ARTICLE,
          event_timestamp: (new Date()).toISOString(),
          user_id: +userId,
          email: localStorage.getItem('email'),
          article_id: this.article.id,
          impacter_id: +key,
          impacter_url: target.href,
          impacter_positions: {
            one: impacter?.relevantPositions[0],
            ...(impacter?.relevantPositions[1] ? {two: impacter?.relevantPositions[1]} : {})
          },
          impacter_article_count: impacter?.articleCount
        });
      }
    }
  }

  async retrieveArticle(territoryUid: string, openSource: string, openSourceId: string, collectiveOrderId?: number): Promise<Article|undefined>{
    this.query = `${this.articleId}`;
    let params = new HttpParams();
    const routeQueryParams = this.route.snapshot.queryParams;
    if (this.trackingEventName === EventTypeName.DOCUMENT_OPENING_FROM_ALERT) {
      params = params.append('rank', routeQueryParams['rank']);
      params = params.append('article_count', routeQueryParams['article_count']);
      params = params.append('admin_doc_count', routeQueryParams['admin_doc_count']);
      params = params.append('document_type', 'article');
      if (collectiveOrderId) params = params.append('collective_order_id', collectiveOrderId);
    }
    if (openSource !== 'web_app') {
      openSource = 'email';
    }
    params = params.append('territories', territoryUid);
    params = params.append('open_source', openSource);
    if (openSourceId) {
      params = params.append('open_source_id', openSourceId);
    }

    try {
      return await this.apiService.article.getArticle(this.query, params, this.trackingEventName).pipe(first()).toPromise();
    } catch (e) {
      console.log(`error when retrieving article: ${e}`);
      return;
    }
  }

  async fetchData(territoryUid: string, openSource: string, openSourceId: string, collectiveOrderId: number) {
    if (!territoryUid) {
      if (this.folderId) {
        territoryUid = 'FRDEPA00';
      } else {
        return;
      }
    }

    const article = await this.retrieveArticle(territoryUid, openSource, openSourceId, collectiveOrderId);
    if(article){
      this.article = article;
      this.article.text = this.utils.updateArticleText(this.article);
    this.entityService.sendDocument(this.article);
    this.documentProviderId = this.article.dataProviderDocumentId?.toString();
    const snippet = await this.contextService
      .getContext(this.currentNavigationState, this.articleId, openSource, openSourceId, collectiveOrderId);
    if (snippet) {
      this.article.snippet = snippet;
    }
    this.documentToBeShared = new SharedDocument({
      document_uid: this.article.id,
      type: this.type,
      title: this.article.title,
      snippet: this.article.snippet ?? '',
      publication_date: this.article.publicationDate,
      territories: this.article.territories,
      source: this.article.source,
      nb_images: this.article.html?.nbImages
    });
    const territoryManager = await firstValueFrom(this.apiService.territory.retrieveTerritories(
      this.article.referenceDepartment ?? territoryUid,
      true,
      true
    ));
    if (territoryManager) {
      this.referenceTerritories = territoriesToUIDArray([territoryManager.territory]);
    }
    this.referenceTerritoriesCounters = await this.apiService.territory.retrieveTerritoryCounters(
        this.article.referenceDepartment,
        true
      ).toPromise();
    this.article.impacters.forEach(impacter => {
      const body = {
        filters: {
          territories: this.referenceTerritories.filter(territory => territory.startsWith('FRCOMM')),
          source: this.referenceTerritoriesCounters?.sourcesTerritories.map(source => source.name)
        },
        impacter_id: impacter.id,
        service: 'count'
      };
      // asynchronously call and set impacter's article count as other articles
      this.apiService.article.getArticleCount(body).toPromise().then((res => impacter.articleCount = res));
    });
    }
  }

  /** This method retrieves the snippet On alert / share opening, and then stores it to localStorage */
  async storeSnippet(openSource: string, openSourceId: string, collectiveOrderId: number) {
    const snippet = await this.contextService
      .getContext(this.currentNavigationState, this.articleId, openSource, openSourceId, collectiveOrderId);
    this.contextService.setSnippetWithExpiry(this.articleId, snippet);
  }

  bookmarkPathsUpdated(id: string, event: Array<any>) {
    this.entityService.sendBookmarkPaths(id, event);
  }
}
