import { AfterViewChecked, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { TreeviewConfig, TreeviewItem } from '../../lib/ngx-treeview';
import { SearchService } from '../../shared/services/search.service';
import roles from '../../../assets/data/roles.json';
import { SearchHelper } from '../../shared/helpers/search.helper';
import { TerritoryKind } from '../../core/territory-kind/territory-kind.enum';
import { ArticleImSearchService } from '../../shared/services/article-im-search.service';
import { departmentContainsEpt, makeSyndicatesTreeview, TerritoryHelper } from '../../shared/helpers/territory.helper';
import { Subscription } from 'rxjs';
import { TerritoryCountService } from '../../shared/services/territory-count.service';
import { ApiService } from '../../shared/services/api/api.service';
import { TerritoryManager } from '../../models/territory/territory-manager';
import { TranslateService } from '@ngx-translate/core';
import { EventTypeName } from '../../models/user-tracker';
import { AdminDocSourceService } from '../../shared/services/admin-doc-source.service';
import {
  AdminDocSourceIssuerGroupData,
  EptTreeviewItem
} from '../../../assets/data/admin-doc-source-data';
import { KeyValue } from '@angular/common';
import { IssuerMethodsService } from '../../shared/services/issuer-methods.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, AfterViewChecked, OnDestroy, OnChanges {
  @Input() currentPage!: string;
  @Input() territoryUid!: string;
  @Input() inImpacterView!: boolean;
  @Input() inDocumentView!: boolean;
  EventTypeName = EventTypeName;
  FilterTrackingName = {
    territoryKind: 'territory-kind',
    syndicate: 'syndicate',
    perimeter: 'perimeter'
  };
  items!: TreeviewItem[];
  syndicates!: TreeviewItem[];
  functionItems: TreeviewItem[];
  values!: number[];
  filterText: string;
  filter: any;
  inSearch = false;
  isCollapsed = false;
  isAllChecked = true;
  territoriesStore!: string[];
  syndicatesStore!: string[];
  titlesStore!: string[];
  selectedTerritories!: string[];
  selectedSyndicates!: string[];
  validSyndicates!: string[];
  oldPosition = false;
  eptTreeviewItem = EptTreeviewItem;
  territoryManager!: TerritoryManager;
  config = TreeviewConfig.create({
    hasFilter: true,
  });
  retrieveTerritoriesSubscription!: Subscription;
  territoriesCountSubscription!: Subscription;

  adminDocSourceSection = new Map<string, AdminDocSourceIssuerGroupData>();
  /*
  Les issuer groups servent à gérer l’affichage et la sélection des trois groupes : pref-and-mrae, syndicates, epci-communes.
  Les syndicats sont gérés séparément des issuers groups
  */
  activeId: string;

  SourceTrackingName = {
    territoryKind: 'territory-kind',
    syndicate: 'syndicate'
  };

  //endregion
  constructor(
    private apiService: ApiService,
    private searchService: SearchService,
    private articleImSearchService: ArticleImSearchService,
    private territoryCountService: TerritoryCountService,
    private translate: TranslateService,
    private adminDocSourceService: AdminDocSourceService,
    private issuerMethodsService: IssuerMethodsService
  ) {
    this.filterText = '';
    this.filter = {
      territories_kind: this.getTerritoriesKindChecked(),
    };
    this.functionItems = this.itemsToTreeView(roles.data);
    this.titlesStore = this.functionItems.reduce((acc: any[], val) => {
      return acc.concat(val.getSelection().checkedItems.map(k => k.value));
    }, []);
    this.filter['titles'] = [...this.titlesStore];
    this.filter['with_old_position'] = this.oldPosition;

    this.activeId = 'accordion-admin-doc-search-comm-and-epci';
  }

  ngOnInit(): void {

    /** object used to manage issuers filter part */
    this.adminDocSourceSection = this.adminDocSourceService.getUpAdminDocSourceStepSectionFromScratch();

    /** management of EPT */
    this.manageEPT();

    /** management of territories and syndicates */
    this.retrieveTerritoriesSubscription =
      this.apiService.territory.retrieveTerritories(this.territoryUid).subscribe(
        (territoryManager: TerritoryManager) => {
          this.territoryManager = territoryManager;
          this.items = TerritoryHelper.transferToTreeview([territoryManager.territory], true);
          this.items.forEach(child => child.setCheckedRecursive(true));

          this.syndicates = makeSyndicatesTreeview(this.territoryManager.syndicates, this.translate.instant('filter.all-syndicates'));

          // adding syndicates to our specific issuer object. Note that the treeviewItems setter updates storedValues too.
          const syndSourceSection = this.adminDocSourceSection.get('syndicates');
          if (syndSourceSection) syndSourceSection.treeviewItems = this.syndicates;

          this.territoriesStore = this.items.reduce((acc: any[], val) => {
            return acc.concat(val.getSelection().checkedItems.map(k => k.value));
          }, []);
          this.selectedTerritories = this.territoriesStore;
          this.syndicatesStore = this.syndicates.reduce((acc: any[], val) => {
            return acc.concat(val.getSelection().checkedItems.map(k => k.value));
          }, []);
          this.selectedSyndicates = this.syndicatesStore;
          this.validSyndicates = this.territoryManager?.getCorrespondingSyndicates(this.selectedTerritories);

          if (this.inImpacterView) {
            this.articleImSearchService.sendPerimeter(this.territoriesStore);
          } else {
            this.searchService.sendPerimeter([...this.territoriesStore, ...this.syndicatesStore]);
          }
          this.setSelectedFilter();
        });

    this.territoriesCountSubscription =
      this.territoryCountService.territoriesCount.subscribe(
        (territoriesCount: any) => {
          this.searchService.getSearch().subscribe(
            (search: any) => {
              this.items.forEach(child => child.setSearchCountRecursive(territoriesCount));
              this.inSearch = !!search['text'] || !!search['topics'];
            });
        });
  }

  ngOnChanges(): void {
    this.updateResourceCount();
  }

  ngOnDestroy() {
    if (this.retrieveTerritoriesSubscription) this.retrieveTerritoriesSubscription.unsubscribe();
    if (this.territoriesCountSubscription) this.territoriesCountSubscription.unsubscribe();
  }

  ngAfterViewChecked() {
    if (this.items) {
      const treeviewContainerRef = document.querySelectorAll<HTMLElement>('.treeview-container');
      if (!treeviewContainerRef) {
        return;
      }
      const MARGIN = 16;
      const tabHeight = this.getElementHeight('.accordion-header', MARGIN);
      const filterHeight = this.getElementHeight('.filter');
      const treeviewHeaderHeight = this.getElementHeight('.treeview-header');
      let height = filterHeight - treeviewHeaderHeight - tabHeight - MARGIN;

      const roleLabel = document.querySelector('.role-label');
      if (roleLabel) {
        const footerHeight = document.querySelector('.footer')?.clientHeight;
        height -= roleLabel.clientHeight + footerHeight!;
      }


      this.config = TreeviewConfig.create({
        ...this.config,
        maxHeight: height
      });
    }
  }

  manageEPT() {
    if (departmentContainsEpt(this.territoryUid.slice(-2))) {
      const commEpciSourceSection = this.adminDocSourceSection.get('comm-and-epci')
      if (commEpciSourceSection) {
        commEpciSourceSection.treeviewItems[0].children
            .push(new TreeviewItem(this.eptTreeviewItem));
        commEpciSourceSection.title = 'alert.issuer-kind.comm-and-epci-and-ept-title';
      }
    }
  }

  getElementHeight(selector: string, outerHeight = 0): number {
    let height = 0;
    document.querySelectorAll(selector).forEach(c => {
      if (c.clientHeight > 0) {
        height += (c.clientHeight + outerHeight);
      }
    });
    return height;
  }

  /** manage territory filtres, including syndicates */
  setSelectedFilter(): void {
    this.filter['territories'] = [
      ...this.selectedTerritories,
      ...this.selectedSyndicates.filter(value => this.validSyndicates.includes(value))
    ];
    this.updateIssuerFilters();
  }

  onSelectedSyndicateChange(value: Array<any>): void {
    const currentSelected = this.selectedSyndicates;
    if (currentSelected.toString() === value.toString()) {
      return;
    }
    this.selectedSyndicates = value;
    this.setSelectedFilter();
  }

  itemsToTreeView(items: any) {
    if (!items) {
      return;
    }
    return items.map((item: any) => new TreeviewItem({
      text: item['text'],
      value: item['value'],
      isRoot: !!item['isRoot'],
      children: this.itemsToTreeView(item['children'])
    }));
  }


  onFunctionChange(value: Array<any>): void {
    if (this.filter['titles'].toString() === value.toString()) {
      return;
    }
    this.filter['titles'] = value;
    this.searchService.sentFilter(this.filter);
  }

  onOldPositionChange(): void {
    this.filter['with_old_position'] = this.oldPosition;
    this.searchService.sentFilter(this.filter);
  }

  onSelectedTerritoryChange(value: Array<any>): void {
    const currentSelected = this.selectedTerritories;
    if (currentSelected.toString() === value.toString()) {
      return;
    }
    if (currentSelected.length === 0) {
      this.isAllChecked = true;
    }
    if (currentSelected.length === this.territoriesStore.length) {
      this.isAllChecked = false;
    }
    this.selectedTerritories = value;
    this.validSyndicates = this.territoryManager?.getCorrespondingSyndicates(value);
    this.setSelectedFilter();
  }

  sendFilter() {
    this.updateResourceCount();
    if (this.inImpacterView) {
      this.articleImSearchService.sentFilter(this.filter);
    } else {
      this.searchService.sentFilter(this.filter);
    }
  }

  getTerritoriesKindChecked(): Array<string> {
    return [];
  }

  itemInSearch(text: string): boolean {
    return !!SearchHelper.searchRegExp(text, this.filterText);
  }

  hideItem(item: TreeviewItem): boolean {
    if (item.children) {
      if (item.isRoot) {
        return false;
      } else {
        const childInSearch = item.children.some(x => this.itemInSearch(x.text));
        return !childInSearch && !this.itemInSearch(item.text);
      }
    } else {
      return !this.itemInSearch(item.text);
    }
  }

  countChecked(item: TreeviewItem) {
    return item.children.reduce((acc, child) => acc + (child.checked ? 1 : 0), 0);
  }

  resetTerritories(event: Event): void {
    this.filterText = '';
    this.items.forEach(child => child.setCheckedRecursive(true));
    this.selectedTerritories = this.territoriesStore;
    this.validSyndicates = this.territoryManager?.getCorrespondingSyndicates(this.selectedTerritories);
    this.setSelectedFilter();
    if (event) {
      event.stopPropagation();
      this.sendFilter();
    }
  }

  resetTitles(event: Event): void {
    this.functionItems.forEach(child => child.setCheckedRecursive(true));
    this.oldPosition = false;
    this.filter['titles'] = [...this.titlesStore];
    this.filter['with_old_position'] = this.oldPosition;

    if (event) {
      event.stopPropagation();
      this.searchService.sentFilter(this.filter);
    }
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    this.items.forEach(child => child.setCollapsedRecursive(this.isCollapsed));
  }

  badgeNumber(currentValue: Array<string>, allValue: Array<any>): string | undefined {
    if (!currentValue || !allValue) {
      return;
    }
    const selectedCount = currentValue.length;
    const allCount = allValue.length;
    if (selectedCount < allCount) {
      return `${selectedCount}/${allCount}`;
    }
    return;
  }

  counter(item: TreeviewItem): number {
    return this.isArticlePage() ? item.infos['articleCount'] : item.infos['adminDocCount'];
  }

  showCounter(item: TreeviewItem): boolean {
    if (this.isImpacterPage()) {
      return false;
    }
    let toShow = item.infos['kind'] === TerritoryKind.COMMUNE;
    if (!this.isArticlePage()) {
      toShow = toShow || item.infos['kind'] === TerritoryKind.EPCI;
    }
    return toShow;
  }

  counterBySearch(item: TreeviewItem): number {
    return item.infos['searchCount'];
  }

  isArticlePage(): boolean {
    return this.currentPage === 'article';
  }

  isImpacterPage(): boolean {
    return this.currentPage === 'impacter';
  }

  updateResourceCount(): void {
    this.filter['resources_count'] = this.items?.[0]?.getSelection()?.checkedItems
      ?.filter(item => this.counter(item)).length;
  }

  //region **issuer selection related methods**

  issuerGroupBadgeNumber(issuerGroup: AdminDocSourceIssuerGroupData): string | undefined {
    if (issuerGroup.treeviewItems) {
      const selection = issuerGroup.treeviewItems[0]?.getSelection();
      const allItemsLength = selection?.checkedItems.length + selection?.uncheckedItems.length;
      if (selection?.checkedItems.length < allItemsLength) {
        return `${selection.checkedItems.length}/${allItemsLength}`;
      }
    }
    return;
  }

  resetIssuerGroupTreeviewItems(issuerGroup: AdminDocSourceIssuerGroupData): void {
    issuerGroup.treeviewItems.forEach((item) => item.setCheckedRecursive(true));

    // update stored values
    issuerGroup.storedValues = issuerGroup.treeviewItems[0].getSelection().checkedItems.map(x => x.value);
    this.selectedSyndicates = this.syndicatesStore;

    this.setSelectedFilter();
  }

  onCheckBoxIssuerGroup(issuerGroup: AdminDocSourceIssuerGroupData): void {
    /** managing disable state of treeview items BEFORE next condition to avoid blocking condition of setCheckedRecursive method */
    issuerGroup.treeviewItems.forEach((item) => {
      item.disabled = !issuerGroup.checked;
      // no update of stored values here
    });
    /** when action from uncheck to checked, issuerGroup is now in checked state.
     * New behaviour #2 VALIDATED */
    if (issuerGroup.checked && issuerGroup.treeviewItems[0].getSelection().checkedItems.length === 0) {
      issuerGroup.treeviewItems.forEach((item) => item.setCheckedRecursive(true));
      // update stored values
      issuerGroup.storedValues = issuerGroup.treeviewItems[0].getSelection().checkedItems.map(x => x.value);
    }

    this.updateIssuerFilters();
  }

  onSelectedIssuerTreeviewItemChanged(event?: any, issuerGroup?: AdminDocSourceIssuerGroupData) {
    if (this.areArraysEqual(event, issuerGroup?.storedValues ?? [])) {
      return;
    } else {
      if (issuerGroup) issuerGroup.storedValues = event;
      if (issuerGroup?.name == 'syndicates') this.onSelectedSyndicateChange(event);
    }
    this.updateIssuerFilters();
  }

  /** method to update search service instance using adminDocSourceSection current data */
  updateIssuerFilters() {
    this.filter['source'] = this.issuerMethodsService.getIssuerKindFrom(this.adminDocSourceSection);
    this.filter['publication_type'] = this.issuerMethodsService.getPublicationType(this.adminDocSourceSection);
    this.sendFilter();
  }

  onAccordionToggle(event: any, issuerGroup: AdminDocSourceIssuerGroupData): void {
    if (event) {
      event.stopPropagation();
    }
    if (this.activeId === issuerGroup.name) {
      this.activeId = '';
      return;
    }
    this.activeId = issuerGroup.name;
  }

  // Order by ascending property value
  valueAscRank = (a: KeyValue<string, AdminDocSourceIssuerGroupData>): number => {
    return a.value.rank;
  }

  areArraysEqual(arr1: string[], arr2: string[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }

    return arr1.every((element, index) => element === arr2[index]);
  }

  //endregion
}
